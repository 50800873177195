import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpClientModule,
} from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environments';
import { Class, Schedule } from '../../../../types/schedules';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  private API_URL = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getSchedules(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/schedules/`));
  }

  getLessons(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/lessons/`));
  }

  getCalendar(month: number, year: number): Promise<any> {
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/utilities/calendar/${year}/month/${month}/`
      )
    );
  }

  updateScheduleName(scheduleId: string, newName: string): Promise<any> {
    const updateData = { name: newName };
    return firstValueFrom(
      this.http.patch(
        `${this.API_URL}/schedules/${scheduleId}/update/`,
        updateData
      )
    );
  }

  addClass(
    scheduleId: string,
    newName: string,
    lessonId: string
  ): Promise<any> {
    const addClassData = { name: newName, lesson: lessonId };
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/schedules/${scheduleId}/classes/create/`,
        addClassData
      )
    );
  }

  getScheduleDetail(id: string): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/schedules/${id}/`));
  }

  getHolidays(start: string, end: string): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/holidays/${start}/${end}`)
    );
  }

  getCountries(): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/utilities/countries/`)
    );
  }

  getStatesByCountry(id: string): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/utilities/countries/${id}/locations/`)
    );
  }

  createScheduleInformation(formData: any): Promise<any> {
    return firstValueFrom(
      this.http.post(`${this.API_URL}/schedules/create/`, formData)
    );
  }

  updateScheduleInformation(formData: any, id: string): Promise<any> {
    return firstValueFrom(
      this.http.put(`${this.API_URL}/schedules/${id}/update/`, formData)
    );
  }
  deleteScheduleInformation(id: string): Promise<any> {
    return firstValueFrom(this.http.delete(`${this.API_URL}/schedules/${id}/`));
  }

  createPlannerbyTermIdLessonId(formData: any): Promise<any> {
    return firstValueFrom(
      this.http.post(`${this.API_URL}/planner/create/`, formData)
    );
  }

  getDays(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/utilities/days/`));
  }

  createBlockSchedule(formData: any): Promise<any> {
    return firstValueFrom(
      this.http.post(`${this.API_URL}/schedules/block/create/`, formData)
    );
  }

  getBlockSchedule(id: string): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/schedules/block/${id}/`)
    );
  }

  getBlockScheduleClasses(id: string, classid: string): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/schedules/block/${id}/class/${classid}/`)
    );
  }

  deleteBlockSchedule(id: string): Promise<any> {
    return firstValueFrom(
      this.http.delete(`${this.API_URL}/schedules/block/${id}/`)
    );
  }

  clearBlockSchedule(id: string, data: boolean): Promise<any> {
    if (data) {
      const options = {
        body: { clear_all: true },
      };
      return firstValueFrom(
        this.http.delete(
          `${this.API_URL}/schedules/block/${id}/clear/`,
          options
        )
      );
    }
    return firstValueFrom(
      this.http.delete(`${this.API_URL}/schedules/block/${id}/clear/`)
    );
  }

  createBlockScheduleDay(id: string, formData: any): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/schedules/block/${id}/add-block-day/`,
        formData
      )
    );
  }

  updateBlockScheduleDay(
    id: string,
    dayid: string,
    formData: any
  ): Promise<any> {
    return firstValueFrom(
      this.http.put(
        `${this.API_URL}/schedules/block/${id}/day/${dayid}/update/`,
        formData
      )
    );
  }

  deleteBlockScheduleDay(id: string, dayid: string): Promise<any> {
    return firstValueFrom(
      this.http.delete(`${this.API_URL}/schedules/block/${id}/day/${dayid}/`)
    );
  }

  createBlockSchedulePeriod(id: string, formData: any): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/schedules/block/${id}/day/add-periods/`,
        formData
      )
    );
  }

  updateBlockSchedulePeriod(id: string, formData: any): Promise<any> {
    return firstValueFrom(
      this.http.put(
        `${this.API_URL}/schedules/block/${id}/day/update-periods/`,
        formData
      )
    );
  }

  deleteBlockSchedulePeriod(id: string): Promise<any> {
    return firstValueFrom(
      this.http.delete(
        `${this.API_URL}/schedules/block/${id}/day/delete-periods/`
      )
    );
  }

  mergeBlockSchedulePeriod(
    id: string,
    dayid: string,
    formData: any
  ): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/schedules/block/${id}/day/${dayid}/merge-periods/`,
        formData
      )
    );
  }

  unmergeBlockSchedulePeriod(
    id: string,
    dayid: string,
    formData: any
  ): Promise<any> {
    const options = {
      body: formData,
    };
    return firstValueFrom(
      this.http.delete(
        `${this.API_URL}/schedules/block/${id}/day/${dayid}/unmerge-periods/`,
        options
      )
    );
  }

  assignBlockScheduleClass(
    id: string,
    dayid: string,
    formData: any
  ): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/schedules/block/${id}/day/${dayid}/assign-class/`,
        formData
      )
    );
  }

  removeBlockScheduleClass(
    id: string,
    dayid: string,
    formData: any
  ): Promise<any> {
    const options = {
      body: formData,
    };
    return firstValueFrom(
      this.http.delete(
        `${this.API_URL}/schedules/block/${id}/day/${dayid}/remove-class/`,
        options
      )
    );
  }

  getNoteData(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/notes/`));
  }
}
