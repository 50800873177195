import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environments';
import { GetSubjectsResponse } from '../../../../types/lesson';

interface SelectedNode {
  name: string;
  id: string;
  children?: any[];
}
interface SelectionItemProps {
  type: string;
  data: any;
  id: string;
  new?: boolean;
  isField?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LessonService {
  private API_URL = `${environment.apiUrl}`;

  public showCurriculum$ = new Subject<boolean>();
  private selectedItem = new Subject<SelectionItemProps>();
  private readonly selectedNodes$ = new BehaviorSubject<SelectedNode[]>([]);
  readonly unitNode$ = this.selectedNodes$.asObservable();

  // public expand$ = new Subject<{
  //   parent?: string;
  //   grand?: string;
  // }>();

  setSelectedItem(value: SelectionItemProps) {
    this.selectedItem.next(value);
    if (value.data.requirement) {
      let nodes = value.data.requirement;

      if (value.type === 'topic' && value.data.topics) {
        const found = value.data.topics.find(
          (topic: any) => topic.id === value.id
        );

        nodes = found.requirement || [];
        console.log('selectedNodes', found);
        console.log('selectedNodes', nodes);
        this.selectedNodes$.next(nodes);
        return;
      }

      // if (value.data.topics && !value.isField) {
      //   value.data.topics.forEach((topic: any) => {
      //     if (topic.requirement && topic.requirement.length > 0) {
      //       nodes = [...nodes, ...topic.requirement];
      //     }
      //   });
      // }

      if (nodes.length > 0) {
        nodes = nodes.filter(
          (node1: SelectedNode, i: number, reNodes: SelectedNode[]) =>
            reNodes.findIndex((node2) => node2.id === node1.id) === i
        );
      }

      this.selectedNodes$.next(nodes);
    } else {
      this.selectedNodes$.next([]);
    }
  }

  getSelectedItem(): Observable<any> {
    return this.selectedItem.asObservable();
  }

  addSelectedNode(value: SelectedNode): void {
    const currentValue = this.selectedNodes$.value;
    const updatedValue = [...currentValue, value];
    this.selectedNodes$.next(updatedValue);
  }

  setSelectedNode(value: SelectedNode[]): void {
    this.selectedNodes$.next(value);
  }

  hideCurriculum(): void {
    this.selectedItem.next({
      type: '',
      data: {},
      id: '',
    });
    this.showCurriculum$.next(false);
  }

  showCurriculum(): void {
    this.showCurriculum$.next(true);
  }

  // setExpand(parent: string, grand: string = ''): void {
  //   this.expand$.next({
  //     parent,
  //     ...(grand && { grand }),
  //   });
  // }

  constructor(private http: HttpClient) {}

  getLessons(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/lessons/`));
  }

  getLessonDetailsById(lessonid: string): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/lessons/${lessonid}`));
  }

  getLessonUnits(lessonid: string): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/lessons/${lessonid}/units/`)
    );
  }
  getLessonUnitsPlan(planid: string): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/planner/${planid}`));
  }

  getLessonUnitsById(lessonid: string, unitid: string): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/lessons/${lessonid}/units/${unitid}`)
    );
  }

  getLessonsUnitsTopics(lessonid: string, unitid: string): Promise<any> {
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/lessons/${lessonid}/units/${unitid}/topics/`
      )
    );
  }

  getLessonsUnitsTopicsById(
    lessonid: string,
    unitid: string,
    topicid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/lessons/${lessonid}/units/${unitid}/topics/${topicid}`
      )
    );
  }

  getLessonsUnitsTopicsActivities(
    lessonid: string,
    unitid: string,
    topicid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/lessons/${lessonid}/units/${unitid}/topics/${topicid}/activities/`
      )
    );
  }

  getLessonsUnitsTopicsActivitiesById(
    lessonid: string,
    unitid: string,
    topicid: string,
    activityid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/lessons/${lessonid}/units/${unitid}/topics/${topicid}/activities/${activityid}`
      )
    );
  }

  createLessonInformation(formData: any): Promise<any> {
    return firstValueFrom(
      this.http.post(`${this.API_URL}/lessons/create/`, formData)
    );
  }

  updateLessonInformation(formData: any, id: string): Promise<any> {
    return firstValueFrom(
      this.http.put(`${this.API_URL}/lessons/${id}/update/`, formData)
    );
  }

  deleteLessonInformation(id: string): Promise<any> {
    return firstValueFrom(this.http.delete(`${this.API_URL}/lessons/${id}/`));
  }

  getGrades(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/utilities/grades`));
  }
  getSubjects(): Promise<GetSubjectsResponse[]> {
    return firstValueFrom(
      this.http.get<GetSubjectsResponse[]>(
        `${this.API_URL}/utilities/subjects/`
      )
    );
  }
  getSubjectByGrade(grade: string): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/utilities/subjects/${grade}`)
    );
  }

  getCurriculums(): Promise<any> {
    const loc = localStorage.getItem('user_location');
    return firstValueFrom(
      this.http.get(`${this.API_URL}/utilities/curriculum-node/${loc}`)
    );
  }

  getCurriculumsDetails(node: string): Promise<any> {
    const loc = localStorage.getItem('user_location');
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/utilities/curriculum-node/${loc}/detail/${node}`
      )
    );
  }
  getRequirementsPerCurriculum(curriculumId: string): Promise<any> {
    const loc = localStorage.getItem('user_location');
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/utilities/curriculum/${loc}/${curriculumId}`
      )
    );
  }

  getTopicRequirementsPerCategory(
    curriculumId: string,
    categoryId: string
  ): Promise<any> {
    const loc = localStorage.getItem('user_location');
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/utilities/curriculum/${loc}/${curriculumId}/${categoryId}`
      )
    );
  }

  createUnitInformation(formData: any, id: string): Promise<any> {
    return firstValueFrom(
      this.http.post(`${this.API_URL}/lessons/${id}/units/create/`, formData)
    );
  }

  createUnitPlanInformation(formData: any, id: string): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/planner/${id}/unit-plans/create/`,
        formData
      )
    );
  }

  updateUnitInformation(
    formData: any,
    id: string,
    unitid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.put(
        `${this.API_URL}/lessons/${id}/units/${unitid}/update/`,
        formData
      )
    );
  }

  deleteUnitInformation(id: string, unitid: string): Promise<any> {
    return firstValueFrom(
      this.http.delete(`${this.API_URL}/lessons/${id}/units/${unitid}/`)
    );
  }

  createTopicInformation(
    formData: any,
    id: string,
    unitid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/create/`,
        formData
      )
    );
  }

  updateTopicInformation(
    formData: any,
    id: string,
    unitid: string,
    topicid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.put(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/update/`,
        formData
      )
    );
  }

  deleteTopicInformation(
    id: string,
    unitid: string,
    topicid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.delete(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/`
      )
    );
  }

  createActivityInformation(
    formData: any,
    id: string,
    unitid: string,
    topicid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/activities/create/`,
        formData
      )
    );
  }

  updateActivityInformation(
    formData: any,
    id: string,
    unitid: string,
    topicid: string,
    activityid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.put(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/activities/${activityid}/update/`,
        formData
      )
    );
  }

  deleteActivityInformation(
    id: string,
    unitid: string,
    topicid: string,
    activityid: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.delete(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/activities/${activityid}/`
      )
    );
  }
  createPlannerbyLessonIdTermId(formData: any): Promise<any> {
    return firstValueFrom(
      this.http.post(`${this.API_URL}/planner/create/`, formData)
    );
  }

  addMaterial(
    formData: any,
    id: string,
    unitid: string,
    topicid: string,
    activityid: string,
    type: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/activities/${activityid}/add-${type}/`,
        formData
      )
    );
  }

  updateMaterial(
    formData: any,
    id: string,
    unitid: string,
    topicid: string,
    activityid: string,
    matid: string,
    type: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.patch(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/activities/${activityid}/update-${type}/${matid}/`,
        formData
      )
    );
  }

  deleteMaterial(
    id: string,
    unitid: string,
    topicid: string,
    activityid: string,
    matid: string,
    type: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.delete(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/activities/${activityid}/update-${type}/${matid}/`
      )
    );
  }

  getMaterialInfo(
    id: string,
    unitid: string,
    topicid: string,
    activityid: string,
    matid: string,
    type: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.get(
        `${this.API_URL}/lessons/${id}/units/${unitid}/topics/${topicid}/activities/${activityid}/update-${type}/${matid}/`
      )
    );
  }

  deletePlanner(schedid: string, classid: string): Promise<any> {
    return firstValueFrom(
      this.http.delete(
        `${this.API_URL}/schedules/${schedid}/classes/${classid}/`
      )
    );
  }

  createClassesOnSchedule(scheduleid: string, formData: any): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${this.API_URL}/schedules/${scheduleid}/classes/create/`,
        formData
      )
    );
  }

  updateClassesOnSchedule(
    scheduleid: string,
    classid: string,
    formData: any
  ): Promise<any> {
    return firstValueFrom(
      this.http.put(
        `${this.API_URL}/schedules/${scheduleid}/classes/${classid}/update/`,
        formData
      )
    );
  }

  deleteClassesOnSchedule(scheduleid: string, formData: any): Promise<any> {
    return firstValueFrom(
      this.http.delete(
        `${this.API_URL}/schedules/${scheduleid}/classes/`,
        formData
      )
    );
  }

  showFileIcon(file: string | File) {
    if (typeof file === 'string') {
      if (file.includes('pdf')) {
        return 'pdf';
      }

      if (file.includes('/vnd.ms-excel')) {
        return 'excel';
      }

      if (file.includes('/msword')) {
        return 'word';
      }

      if (file.includes('pptx')) {
        return 'pptx';
      }

      return 'img';
    }

    if (file.type.includes('pdf')) {
      return 'pdf';
    }

    if (file.type.includes('/vnd.ms-excel')) {
      return 'excel';
    }

    if (file.type.includes('/msword')) {
      return 'word';
    }

    if (file.type.includes('pptx')) {
      return 'pptx';
    }

    return 'img';
  }

  showFileName(file: string | File) {
    if (typeof file === 'string') {
      return file.substring(file.lastIndexOf('/') + 1);
    }
    return file.name;
  }
}
